<script setup lang="ts">

const {$activeModalsBus} = useNuxtApp();
const show = ref(false);
$activeModalsBus.listen('openPopupPhone', () => {
  show.value = true;
});
$activeModalsBus.listen('closePopupPhone', () => {
  show.value = false;
});


</script>

<template>
  <v-dialog v-model="show" :max-width="800">
    <v-card>
      <div class="modal-body p-0">
        <div class="row">
          <div class="col-md-6">
            <div class="col-md-10 mx-auto py-4">

              <div class="title--sm text-center font-weight-bold">
                ליצירת קשר
              </div>
              <div class="subtitle-semibold text-center font-weight-bold">
              </div>
              <div class="text-center form--black mt-4 mb-3">
                <p style="text-align: center;">עליך לחייג למספר הבא:<br>
                  05555-22-553</p>
              </div>
            </div>
          </div>
          <div class="col-md-6 cover"
               style="background-image: url('https://admin.sasa.co.il/wp-content/uploads/2020/12/red-car-pic.jpg')">
            <img class="mobile_only"
                 src="https://admin.sasa.co.il/wp-content/uploads/2020/12/red-car-pic.jpg"
                 alt="תמונה של רכב אדום לחלון נפתח של יצירת קשר">
          </div>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<style scoped lang="scss">

</style>